/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Spin, Row, Col, Table, message, Form, Upload } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { ContainerStyle, ApplicationDetailsWrapper, MainStyle } from './Style';
import { DataService } from '../../../config/dataService/dataService';
import { Button } from '../../../components/buttons/buttons';
import ReissueModal from '../../../components/modal/ReissueModal';
import { RightBlockStyle } from '../style';
import PoweredBy from '../Landing/PowerdBy';
import { validImageTypes } from '../../../utility/utility';

// const { TabPane } = Tabs;
export default function ApplicationDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const [details, setDetails] = useState({});
  const [paydetails, setPaydetails] = useState([]);
  const [invoiceCreatable, setInvoiceCreatable] = useState(false);
  const [isReissue, setIsReissue] = useState(false);
  const [reissueInvoice, setReissueInvoice] = useState();
  const [loading, setLoading] = useState(true);
  const [docLoader, setDocLoader] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [countryName, setCountryName] = useState('');
  const mainDocsList = [
    { type: 'passport' },
    { type: 'criminal' },
    { type: 'selfie' },
    { type: 'proof_of_res' },
    { type: 'marriage_certificate' },
  ];
  const spouseDocsList = [{ type: 'passport' }, { type: 'criminal' }, { type: 'selfie' }, { type: 'proof_of_res' }];
  const childDocsList = [
    { type: 'passport' },
    { type: 'children_criminal_record' },
    { type: 'selfie' },
    { type: 'children_co_residence' },
    { type: 'birth_certificate' },
  ];

  const getUserPaymentDetails = () => {
    if (location && location?.state && location?.state?.isAdmin && location?.state?.uid) {
      DataService.get(`/userApplications/uid/${location?.state?.uid}`)
        .then((res) => {
          DataService.get(`/bitfinex/getUserPayments/${res.data.userId}`).then((resPayments) => {
            if (resPayments.data.length === 0) {
              setInvoiceCreatable(true);
            } else {
              setInvoiceCreatable(false);
            }
            const tempPay = [];
            resPayments &&
              resPayments.data.length &&
              resPayments.data.map((item) => {
                tempPay.push({
                  ...item,
                  invoiceID: item?.invoiceData[0].invoiceID,
                });
                return tempPay;
              });
            setPaydetails(tempPay);
          });
          setLoading(false);
          setDetails(res.data);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      DataService.get(`/userApplications/user?sort=createdAt&order=-1&limit=1`)
        .then((res) => {
          DataService.post('/bitfinex/getUserPayments').then((resPayments) => {
            if (resPayments.data.length === 0) {
              setInvoiceCreatable(true);
            } else {
              setInvoiceCreatable(false);
            }
            const tempPay = [];
            resPayments.data.map((item) => {
              tempPay.push({
                ...item,
                invoiceID: item?.invoiceData[0].invoiceID,
              });
              return tempPay;
            });
            setPaydetails(tempPay);
            setLoading(false);
            setDetails(res.data[0]);
          });
          setDocLoader(false);
        })
        .catch(() => {
          setLoading(false);
          setDocLoader(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserPaymentDetails();
  }, []);

  useEffect(() => {
    if (details && details.resid_country) {
      DataService.get(`/country/code/${details.resid_country}`)
        .then((res) => {
          setCountryName(res.data.name);
        })
        .catch(() => {});
    }
  }, [details]);

  const paymentColumns = [
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) =>
        item === 'EXPIRED' ? (
          <Button
            type="primary"
            onClick={() => {
              setIsReissue(true);
              setReissueInvoice(record);
            }}
          >
            Generate New Payment Link
          </Button>
        ) : (
          <a
            target="_blank"
            href={record?.invoiceData[0].invoiceURL}
            rel="noreferrer"
            style={{ display: record.status === 'COMPLETED' ? 'none' : 'block' }}
          >
            Payment Link
          </a>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Payment Date',
      dataIndex: 't',
      key: 't',
      render: (item) => moment(item).format('MM-DD-YYYY | hh:mm:ss'),
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
  ];

  const verifiedColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Document Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Uploaded At',
      dataIndex: 'upload_at',
      key: 'upload_at',
      render: () => moment().format('MM-DD-YYYY | hh:mm:ss'),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text) => (text ? 'Yes' : 'No'),
    },
  ];

  const familyColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => {
        return `${record?.first_name} ${record?.middle_name} ${record?.last_name}`;
      },
    },
    {
      title: 'Family Relationship',
      dataIndex: 'family_relationship',
      key: 'family_relationship',
    },
  ];

  const checkReplaceSpecialCharacter = (value) => {
    return value?.replace(/[^a-zA-Z0-9.]/g, '_');
  };

  const uploadRemainingDocs = async (id, doc) => {
    const updatedFileName = checkReplaceSpecialCharacter(doc?.filename);
    doc.filename = updatedFileName;
    const body = {
      _id: id,
      documents: [doc],
    };
    setDocLoader(true);
    DataService.post('/userApplications/uploadDocuments', body)
      .then((res) => {
        if (res.status === 201) {
          message.success(t('application_details_docs_uploaded_msg'));
          getUserPaymentDetails();
        } else {
          message.error(t('duplicate_upload_file_message'));
          setDocLoader(false);
        }
      })
      .catch((err) => {
        console.log('error', err);
        message.error(t('duplicate_upload_file_message'));
        setDocLoader(false);
      });
  };

  const customRequest = async (event, id, accountId, record) => {
    console.log('record', record);
    const reader = new FileReader();
    const { file, onSuccess, onError } = event;
    try {
      if (!validImageTypes(file)) {
        onError('Select only JPG or PNG image');
        return false;
      }
      reader.onload = (e) => {
        const base64Data = e.target.result;

        const doc = {
          type: id,
          data: base64Data,
          filename: file.name,
        };
        uploadRemainingDocs(accountId, doc);
        onSuccess();
      };
      onSuccess();

      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Custom request error:', error);
      onError(error);
    }
  };

  const handleFileChange = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length === 0) {
      return null;
    }
    return e && e.fileList;
  };

  const getPendingDocsList = (item, docsList) => {
    return docsList
      ?.filter((firstItem) => item?.documents?.every((secondItem) => secondItem?.type !== firstItem?.type))
      ?.map((firstItem) => ({
        ...firstItem,
        account_id: item?.applicationId,
        first_name: item?.first_name,
        last_name: item?.last_name,
      }));
  };

  const getMainPendingDocsList = (item, docsList) => {
    if (details.marital_status !== 'married') {
      docsList.pop();
    }
    return docsList
      ?.filter((firstItem) => item?.every((secondItem) => secondItem?.type !== firstItem?.type))
      ?.map((firstItem) => ({
        ...firstItem,
        account_id: details?.applicationId,
        first_name: details?.first_name,
        last_name: details?.last_name,
        marital_status: details?.marital_status,
      }));
  };

  const getPendingDocs = (item, type, docsList) => {
    return (
      item?.family_relationship === type &&
      docsList?.filter((firstItem) => {
        // Check if any object in the second array has a property value matching the item
        return !item?.documents?.some((secondItem) => secondItem?.type === firstItem?.type);
      })?.length
    );
  };

  const isDocsPending = (type, docsList) => {
    const isPending =
      details?.familyMembers &&
      details?.familyMembers?.map((item) => {
        return getPendingDocs(item, type, docsList);
      });
    if (isPending) {
      return isPending[0];
    }
    return false;
  };

  const submitInvoice = async () => {
    const invoiceData = {
      amount: process.env.REACT_APP_APPLICATION_FEE,
      currency: 'USD',
      duration: process.env.REACT_APP_APPLICATION_PAYMENT_DURATION,
      nationality: details.nationality,
      residCountry: details.resid_country,
      residStreet: details.resid_street,
      residZipCode: details.resid_zipcode,
      residBuildingNo: details.resid_building_name,
      residCity: details.resid_city,
      fullName: `${`${details.first_name} ${details.middle_name ? details.middle_name : ''} ${details.last_name}`}`,
      email: details?.email ? details.email : JSON.parse(Cookies.get('user')).email,
      paymentType: 'ApplicationPayment',
      userId: details.userId,
    };
    // Hotfix applied on 24th jan for USA (Renounced Citizenship)
    if (invoiceData.nationality === '840') {
      invoiceData.nationality = invoiceData.residCountry;
    }
    setInvoiceLoading(true);
    await DataService.post('/bitfinex/submitInvoice', invoiceData)
      .then((resInvoice) => {
        if (resInvoice.status === 201) {
          const url = `https://pay.bitfinex.com/gateway/order/${resInvoice.data}`;
          const windowFeatures = 'width=600,height=800,resizable,scrollbars';
          window.open(url, '_blank', windowFeatures);
        } else {
          message.error(t('unknown_error_message'));
        }
        setInvoiceLoading(false);
        getUserPaymentDetails();
      })
      .catch(() => {
        message.error(t('unknown_error_message'));
        getUserPaymentDetails();
        setInvoiceLoading(false);
      });
  };

  const filteredData = details?.documents?.filter((record) => {
    if (
      record.type === 'passport' ||
      record.type === 'proof_of_res' ||
      record.type === 'selfie' ||
      record.type === 'criminal' ||
      record.type === 'marriage_certificate'
    ) {
      return record;
    }
  });

  if (loading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <ContainerStyle>
      <MainStyle>
        <div className="left-container">
          <div className="left-main">
            <h1 className="left-heading">
              YOUR <br />
              {t('APPLICATION')} <br /> OVERVIEW
            </h1>
          </div>
        </div>
        <div className="right-container">
          <div className="right-main">
            <Row justify="center">
              <Col xxl={20} xl={20} md={22} sm={18} xs={24}>
                <RightBlockStyle>
                  <div className="main">
                    <div className="ninjadash-authentication-top">
                      <ApplicationDetailsWrapper>
                        <h5 className="heading5">General information</h5>
                        <div className="status-block">
                          <p>
                            Application Type:
                            <span className="individual">{details?.application_type}</span>
                          </p>
                          <p>
                            E-Mail:
                            <span className="individual" style={{ textTransform: 'none' }}>
                              {details?.email}
                            </span>
                          </p>
                        </div>
                        <div className="status-block">
                          <p>
                            Mobile Number:
                            <span className="individual">{details?.phone}</span>
                          </p>
                          <p>
                            Status:
                            <span>{details?.applicationStatus?.toUpperCase() || 'N/A'}</span>
                          </p>
                        </div>
                        <div className="application-id">
                          Application ID: <span className="status-text">{details?.applicationId || 'N/A'}</span>
                        </div>
                        <h5 className="heading5">Payment Details</h5>
                        <Table
                          dataSource={paydetails}
                          columns={paymentColumns}
                          loading={loading}
                          className="table-responsive"
                          pagination={false}
                        />
                        {invoiceCreatable && (
                          <Button
                            type="primary"
                            onClick={submitInvoice}
                            disabled={
                              invoiceLoading ||
                              isDocsPending('spouse', spouseDocsList) ||
                              isDocsPending('child', childDocsList) ||
                              getMainPendingDocsList(details?.documents, mainDocsList)?.length
                            }
                            loading={invoiceLoading}
                          >
                            {t('Submit Payment')}
                          </Button>
                        )}
                        <h5 className="heading5">Basic Details</h5>
                        <div className="status-block">
                          <p>
                            Building Name:
                            <span className="individual">{`${details?.resid_building_name}`}</span>
                          </p>
                          <p>
                            Street:
                            <span className="individual">{details?.resid_street}</span>
                          </p>
                        </div>
                        <div className="status-block">
                          <p>
                            ZIP Code:
                            <span className="individual">{details?.resid_zipcode}</span>
                          </p>
                          <p>
                            City:
                            <span className="individual">{details?.resid_city}</span>
                          </p>
                        </div>
                        <div className="status-block">
                          <p>
                            State:
                            <span className="individual">{details?.resid_state}</span>
                          </p>
                          <p>
                            Country:
                            <span className="individual">{countryName}</span>
                          </p>
                        </div>
                        <h5 className="heading5">Submitted Documents</h5>
                        <Table
                          className="table-responsive"
                          pagination={false}
                          dataSource={filteredData}
                          columns={verifiedColumns}
                          loading={loading}
                        />
                        <Spin spinning={docLoader}>
                          {getMainPendingDocsList(details?.documents, mainDocsList)?.length ? (
                            getMainPendingDocsList(details?.documents, mainDocsList).map((record) => {
                              if (record.type === 'marriage_certificate' && record?.marital_status !== 'married') {
                                return <></>;
                              }
                              return (
                                <div className="error-msg">
                                  <Form.Item
                                    name={record.type}
                                    valuePropName="fileList"
                                    getValueFromEvent={handleFileChange}
                                    rules={[
                                      {
                                        required: true,
                                        message: `${t('application_details_choose_validation')} ${record.type}`,
                                      },
                                    ]}
                                    className="upload-display"
                                  >
                                    <b>{record.type}</b> {t('of')} {record.first_name} {record.last_name}{' '}
                                    {t('is missing')},
                                    <Upload
                                      listType="picture"
                                      accept=".jpg,.jpeg,.png,.pdf"
                                      maxCount={1}
                                      customRequest={(e) => customRequest(e, record.type, record.account_id, record)}
                                    >
                                      <span className="click">{t('application_details_click_here')}</span>
                                    </Upload>
                                    {t('application_details_dup_error_msg')}
                                  </Form.Item>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          {details?.familyMembers && details?.familyMembers?.length && (
                            <>
                              <h5 className="heading5">{t('application_details_family_details_title')}</h5>
                              <Table
                                className="table-responsive"
                                pagination={false}
                                dataSource={details?.familyMembers}
                                columns={familyColumns}
                                loading={loading}
                              />
                            </>
                          )}
                          {details?.familyMembers &&
                            details?.familyMembers?.length &&
                            details?.familyMembers.map((item) => {
                              if (getPendingDocs(item, 'spouse', spouseDocsList)) {
                                return (
                                  <>
                                    {getPendingDocsList(item, spouseDocsList).map((record) => (
                                      <div className="error-msg">
                                        <Form.Item
                                          name={record.type}
                                          valuePropName="fileList"
                                          getValueFromEvent={handleFileChange}
                                          rules={[
                                            {
                                              required: true,
                                              message: `${t('application_details_choose_validation')} ${record.type}`,
                                            },
                                          ]}
                                          className="upload-display"
                                        >
                                          <b>{record.type}</b> {t('of')} {record.first_name} {record.last_name}{' '}
                                          {t('application_details_is_missing')}
                                          <Upload
                                            listType="picture"
                                            accept=".jpg,.jpeg,.png,.pdf"
                                            maxCount={1}
                                            customRequest={(e) =>
                                              customRequest(e, record.type, record.account_id, record)
                                            }
                                          >
                                            <span className="click">{t('application_details_click_here')}</span>
                                          </Upload>
                                          {t('application_details_dup_error_msg')}
                                        </Form.Item>
                                      </div>
                                    ))}
                                  </>
                                );
                              }
                              if (getPendingDocs(item, 'child', childDocsList)) {
                                return (
                                  <>
                                    {getPendingDocsList(item, childDocsList).map((record) => (
                                      <div className="error-msg">
                                        <Form.Item
                                          name={record.type}
                                          valuePropName="fileList"
                                          getValueFromEvent={handleFileChange}
                                          rules={[
                                            {
                                              required: true,
                                              message: `${t('application_details_choose_validation')} ${record.type}`,
                                            },
                                          ]}
                                          className="upload-display"
                                        >
                                          <b>{record.type}</b> {t('of')} {record.first_name} {record.last_name}{' '}
                                          {t('application_details_is_missing')}
                                          <Upload
                                            listType="picture"
                                            accept=".jpg,.jpeg,.png,.pdf"
                                            maxCount={1}
                                            customRequest={(e) =>
                                              customRequest(e, record.type, record.account_id, record)
                                            }
                                          >
                                            <span className="click">{t('application_details_click_here')}</span>
                                          </Upload>
                                          {t('application_details_dup_error_msg')}
                                        </Form.Item>
                                      </div>
                                    ))}
                                  </>
                                );
                              }
                              return <></>;
                            })}
                        </Spin>
                      </ApplicationDetailsWrapper>
                    </div>
                  </div>
                </RightBlockStyle>
              </Col>
            </Row>
          </div>
          <ReissueModal
            isReissue={isReissue}
            setIsReissue={setIsReissue}
            invoiceData={reissueInvoice}
            details={details}
          />
        </div>
      </MainStyle>
      <div className="bottom" />
      <PoweredBy />
    </ContainerStyle>
  );
}

import styled from 'styled-components';

const ViewStatusWraper = styled.div`
  .assets-detail-paragraph-head {
    font-size: 14px;
    line-height: 10px;
    color: rgb(47, 50, 65) !important;
    font-style: normal;
    font-weight: 500;
    text-align: left !important;
  }
  .assets-detail-paragraph-text {
    font-size: 14px;
    line-height: 10px;
    font-style: normal;
    font-weight: 500;
    color: rgb(147, 147, 147) !important;
    text-align: left !important;
  }

  table {
    width: 100%;
    text-align: center;
  }

  .ant-input {
    border: 1px solid #e3e6ef !important;
    text-align: left !important;
  }
  .ant-input::placeholder {
    text-align: left !important;
  }
`;

const TableHeaderStyle = styled.div`
  .search-input {
    border: 1px solid #e5e5e5 !important;
    border-bottom: 0px !important;
    margin-left: 24px !important;
    text-align: left !important;
    padding: -4px !important;
    border-radius: 4px !important;
  }

  .ant-input[type='text'] {
    border-bottom: 1px solid #e5e5e5 !important;
  }

  .ant-select-selector {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-bottom: 0px solid #e5e5e5 !important;
    padding: auto !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px !important;
    padding: auto !important;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    background: #0042a8 !important;
    left: 0 !important;
  }

  .table-header-block {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;

    .drop-down-select {
      border: 1px solid #e5e5e5 !important;
      background-color: #fff !important;
      border-radius: 0px !important;
      text-align: left !important;
    }

    .text-block {
      display: flex;
      flex-direction: column;
      .text-size {
        font-size: 13px;
        margin-bottom: 8px;
      }
    }

    .select-margin {
      margin-left: 24px;
    }

    .select-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .select-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .select-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
`;

const OverviewDataStyleWrap = styled.div`
  &.card-mesh-wrap {
    justify-content: space-between;
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
    @media only screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
    .ninjadash-overview-card-single {
      flex: 0 0 auto;
      margin-bottom: 0;
      @media only screen and (max-width: 991px) {
        flex: 0 0 50%;
      }
      @media only screen and (max-width: 575px) {
        flex: 0 0 100%;
      }
    }
  }
`;

export { ViewStatusWraper, OverviewDataStyleWrap, TableHeaderStyle };

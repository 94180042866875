/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const validImageTypes = (file) => {
  const fileType = file.type;
  const types = ['image/jpeg', 'image/png', 'application/pdf'];
  if (!types.includes(fileType)) {
    return false;
  }
  return true;
};

const checkPasswordValid = (value, setState) => {
  const checkPassword = value;
  if (checkPassword.length >= 8) {
    setState((state) => {
      return { ...state, minimumChar: true };
    });
  } else {
    setState((state) => {
      return { ...state, minimumChar: false };
    });
  }
  if (/\d/.test(checkPassword)) {
    setState((state) => {
      return { ...state, numberValid: true };
    });
  } else {
    setState((state) => {
      return { ...state, numberValid: false };
    });
  }
  if (/[@$!%*#?&]/.test(checkPassword)) {
    setState((state) => {
      return { ...state, specialCharValid: true };
    });
  } else {
    setState((state) => {
      return { ...state, specialCharValid: false };
    });
  }
  if (/[A-Z]/.test(checkPassword)) {
    setState((state) => {
      return { ...state, uppercaseValid: true };
    });
  } else {
    setState((state) => {
      return { ...state, uppercaseValid: false };
    });
  }
  if (/[a-z]/.test(checkPassword)) {
    setState((state) => {
      return { ...state, lowercaseValid: true };
    });
  } else {
    setState((state) => {
      return { ...state, lowercaseValid: false };
    });
  }
};

export const getRegexExp = () => {
  const ENGLISH = 'a-zA-Z';
  const LATIN = '\u00C0-\u00FF';
  const CYRILLIC = '\u0400-\u04FF';
  const CHINESE = '\u2E80-\u2FDF\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF';
  const TURKISH = '\u00C7\u00E7\u011E\u011F\u0131\u0130\u00D6\u00F6\u015E\u015F\u00DC\u00FC\u0307';
  const CHARACTERS = `${ENGLISH}${LATIN}${CYRILLIC}${CHINESE}${TURKISH}`;

  const REG_EXPS = {
    NUMBER: /^\d+(\.\d+)?$/,
    EMAIL: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    PATH: /([^/]*)\/*$/,
    // eslint-disable-next-line no-misleading-character-class
    NAME: new RegExp(`^[-${CHARACTERS}' &()/]+$`),
    // eslint-disable-next-line no-misleading-character-class
    INPUT: new RegExp(`^[-0-9.,;:<>?/\n${CHARACTERS}'_ &()/+@%]+$`),
    URL: /^[-a-zA-Z0-9@:%.;&?+,_#=/]{2,256}$/,
    PHONE_CODE: /^(\+)*[0-9]+$/,
    IMAGE: /^data:image\/[A-Za-z]+;base64,[A-Za-z0-9+/=]+$/,
    FILE: /^data:(image|application|video)\/[A-Za-z]+;base64,[A-Za-z0-9+/=]+$/,
  };

  return REG_EXPS;
};

export { ellipsis, idGenerator, validImageTypes, checkPasswordValid };
